import logo from '../../logo.png'
import './App.css'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { Button, Paper } from '@material-ui/core'
import LoginIcon from '@mui/icons-material/Login'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

function App() {
  return (
    <Container maxWidth="sm" className="App" >
      <Paper>
        <img src={logo} className="App-logo" alt="logo" />
        <Typography variant="p" component="h4" gutterBottom>
          Rotinas reais, hábitos saudáveis
        </Typography>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField label="Email" color="secondary" focused />
            <TextField label="Senha" color="secondary" focused type="password" autoComplete="current-password" /> 
          </div>
        </Box>
        <Button variant="outlined" color="primary" startIcon={<LoginIcon />}>
          Entrar
        </Button>
      </Paper>
    </Container>
  )
}

export default App
